import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  loginApi,
  signupApi,
  forgotPasswordApi,
  GetAllNotifications,
} from '../../apis/api';
import toast from 'react-hot-toast';

// Define async thunks
export const login = createAsyncThunk('user/login', async (credentials) => {
  try {
    const res = await fetch(`https://api.malwamarket.com/api/login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });
    const response = await res.json();

    if (response.success && response?.ROLE != 'admin') {
      localStorage.setItem('token', response?.TOKEN);
      toast.success(response.message);
      return response;
    }
    if (response.success && response?.ROLE == 'admin') {
      toast.error('invalid email address and password');
      throw new Error('invalid email address and password');
    } else {
      toast.error(response.message);
      throw new Error(response.message);
    }
  } catch (error) {
    toast.error('invalid email address and password');
    throw new Error('invalid email address and password');
  }
});

export const signup = createAsyncThunk('user/signup', async (userData) => {
  try {
    const response = await signupApi(userData);
    if (response.success) {
      localStorage.setItem('token', response?.TOKEN);
      toast.success('Sign up successful');
      return response;
    } else {
      toast.error(response.message);
      throw new Error(response.message);
    }
  } catch (error) {
    toast.error('An error occurred during signup');
    throw new Error('An error occurred during signup');
  }
});

export const forgotPassword = createAsyncThunk(
  'user/forgotPassword',
  async (email) => {
    try {
      const response = await forgotPasswordApi(email);
      return response;
    } catch (error) {
      throw new Error('An error occurred during password reset');
    }
  }
);

export const fetchNotifications = createAsyncThunk(
  'user/fetchNotifications',
  async () => {
    try {
      const response = await GetAllNotifications();
      return response;
    } catch (error) {
      throw new Error('An error occurred while fetching notifications');
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loggedIn: false,
    userData: {},
    token: '',
    login_popup: false,
    notifications: {
      data: [],
      unreadCount: 0,
      status: 'idle',
      error: null,
    },
  },
  reducers: {
    resetUser(state) {
      state.loggedIn = false;
      state.userData = {};
      state.token = '';
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    setLoggedIn(state, action) {
      state.loggedIn = action.payload;
    },
    setLoginPopup(state, action) {
      state.login_popup = action.payload;
    },
    logout(state) {
      state.loggedIn = false;
      state.userData = {};
      state.token = '';
      state.login_popup = false;
      localStorage.removeItem('token');
      toast.success('Logged out successfully');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.loggedIn = true;
        state.userData = action.payload;
        state.token = action.payload.TOKEN;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.loggedIn = true;
        state.userData = action.payload;
        state.token = action.payload.TOKEN;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        // Handle forgot password success if needed
      })
      .addCase(fetchNotifications.pending, (state) => {
        state.notifications.status = 'loading';
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications.status = 'succeeded';
        state.notifications.data = action.payload?.data;
        state.notifications.unreadCount = action.payload?.unreadCount;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.notifications.status = 'failed';
        state.notifications.error = action.error.message;
      });
  },
});

export const {
  resetUser,
  setLoggedIn,
  setUserData,
  setToken,
  setLoginPopup,
  logout,
} = userSlice.actions;

export default userSlice.reducer;
