import React, { useState } from 'react';
import { MdOutlineLocationOn } from 'react-icons/md';
import AddressModal from './LocationModal';
import { Tooltip } from 'react-tooltip';

const Location = () => {
  const [open, setOpen] = useState(false);
  const pincode = localStorage.getItem('pincode') || 'Hatpipliya';

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className="flex justify-end pr-[4px] max-w-fit items-center md:ml-auto gap-[4px] md:gap-[10px] "
      >
        <div className="h-[30px] ml-auto flex justify-center items-center min-w-[30px] rounded-[50%] bg-[#0474ED]">
          <MdOutlineLocationOn
            color="white"
            className="md:text-gray-700 text-white text-[20px]"
          />
        </div>
        <div>
          <div
            className="text-gray-800 font-semibold max-w-[200px] line-clamp-1"
            data-tooltip-id="pincodeTooltip"
            data-tooltip-content={pincode}
            data-tooltip-place="bottom-start"
          >
            {pincode}
          </div>
          <Tooltip
            className="!z-[51] relative right-[100%]"
            id="pincodeTooltip"
            place="bottom"
            effect="solid"
          />
        </div>
      </div>
      {open && <AddressModal isOpen={open} setIsOpen={setOpen} />}
    </>
  );
};

export default Location;
