import React, { useEffect, useState } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Sliders from '../components/Sliders';
import { getApi } from '../utils/apis';

const SubCategory2 = () => {
  const navigate = useNavigate();
  const { slider_img_list } = useSelector((state) => state.alldata);
  const [cat_list, setCat_list] = useState([]);
  const { state } = useLocation();
  console.log(state);

  const banner_top = slider_img_list?.filter(
    (v) => v.PAGE_NAME == 'Sub-Categoty Top'
  )[0]?.DATA_BASED_ON_PAGE_NAME;
  const banner_bottom = slider_img_list?.filter(
    (v) => v.PAGE_NAME == 'Sub-Categoty Bottom'
  )[0]?.DATA_BASED_ON_PAGE_NAME;

  const get_data = async () => {
    const res = await getApi(`services1/list/ser_id/${state.ID}`);
    console.log(res);
    if (res.success) {
      setCat_list(res.data);
      if (res.data?.length == 0) {
        navigate(`/${state.S_NAME}/service-providers`, {
          state: {
            name: 'subcategory1',
            id: state?.ID,
            // url: [...state?.url, state.ID?.S_NAME],
          },
        });
      }
    } else {
      setCat_list([]);
    }
  };

  console.log(cat_list, 'abhishek');

  useEffect(() => {
    get_data();
  }, []);
  const handleClick = (item) => {
    navigate(`/${state.url}/${item?.S_NAME}/service-providers`, {
      state: {
        name: 'subcategory2',
        id: item?.ID,
        url: [...state.url, item?.S_NAME],
      },
    });
  };
  return (
    <div className="px-4 md:px-10">
      <Breadcrumb current_page={'Sub Category'} />
      <Sliders list={banner_top} list2={banner_bottom} />
      {cat_list?.length === 0 && (
        <div className="flex justify-center">
          <p className="text-[32px] font-semibold">No Data Found</p>
        </div>
      )}
      <div className="mb-5">
        <h1 className="text-2xl mt-4 font-sans font-semibold capitalize">
          {state?.S_NAME}
        </h1>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        {cat_list.map((v, i) => (
          <div
            onClick={() => handleClick(v)}
            key={i}
            className="flex w-full items-center gap-4 cursor-pointer relative group h-20  rounded-lg bg-white box_shadow px-8  transition-all duration-300 ease-in-out  overflow-visible"
          >
            <img
              src={process.env.REACT_APP_IMAGE_URL + v.IMAGE_URL}
              className="h-[40px] w-[40px] object-cover transition-all duration-300 ease-in-out group-hover:h-[45px] group-hover:w-[45px]"
            />
            <p className="">{v.S_NAME}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubCategory2;
