import React, { useState } from 'react';
import user from '../assets/images/user1.png';
import { FiLogIn, FiPhoneCall, FiUser, FiHome } from 'react-icons/fi';
import { RiBloggerLine } from 'react-icons/ri';
import { IoPricetagsOutline } from 'react-icons/io5';
import { HiOutlineBriefcase, HiOutlineMail } from 'react-icons/hi';
import { store } from '../redux/store';
import { setLoginPopup } from '../redux/slice/userSlice';
import { Link, useNavigate } from 'react-router-dom';
import Contact from './ContactPopup';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/slice/userSlice';
import { Image } from 'antd';
import ConfirmationModal from './LogoutPopup';

const HeaderProfile = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const loggedIn = useSelector((state) => state.user?.loggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <div className="relative group block">
        <div className="py-3">
          <img
            src={
              data?.IMAGE_URL && loggedIn
                ? `${process.env.REACT_APP_IMAGE_URL}${data?.IMAGE_URL}`
                : user
            }
            className="h-[40px] w-[40px] object-cover rounded-[50%]"
          />
        </div>
        <div
          className="absolute end-0 z-10 w-56 rounded-md border border-gray-100 bg-white shadow-xl hidden group-hover:block"
          role="menu"
        >
          <div className="p-2">
            <Link
              to="/profile"
              className="flex items-center hover:text-white gap-2 rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-[#57A7FF] "
              role="menuitem"
            >
              <FiUser size={20} /> Profile
            </Link>
            <Link
              to="/why-malwa-market"
              className="flex items-center hover:text-white gap-2 rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-[#57A7FF] "
              role="menuitem"
            >
              <FiPhoneCall size={20} /> Why Malwa Market
            </Link>

            <Link
              className="flex items-center hover:text-white gap-2 rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-[#57A7FF] "
              to="/about-us"
            >
              <FiHome size={20} /> About Us
            </Link>

            <Link
              className="flex items-center hover:text-white gap-2 rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-[#57A7FF] "
              to="/your-business"
            >
              <HiOutlineBriefcase size={20} /> List your businessss
            </Link>

            <p
              className="flex cursor-pointer hover:text-white items-center gap-2 rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-[#57A7FF] hover:text-black"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <HiOutlineMail size={20} /> Contact Us
            </p>

            <Link
              to="/plan-and-pricing"
              className="flex items-center hover:text-white gap-2 rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-[#57A7FF] "
              role="menuitem"
            >
              <IoPricetagsOutline size={20} /> Plan & Pricing
            </Link>

            <Link
              to="/blogs"
              className="flex items-center hover:text-white gap-2 rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-[#57A7FF] "
              role="menuitem"
            >
              <RiBloggerLine size={20} /> Blogs
            </Link>

            <button
              type="submit"
              onClick={async () => {
                if (!loggedIn) store.dispatch(setLoginPopup(true));
                if (loggedIn) {
                  setModalOpen(true);
                }
              }}
              className="w-full flex items-center gap-2 rounded-lg px-4 py-2 text-sm text-gray-500 hover:text-white hover:bg-[#57A7FF]"
            >
              <FiLogIn size={20} /> {loggedIn ? 'Log Out' : 'Log In'}
            </button>
          </div>
        </div>
      </div>
      <ConfirmationModal
        onConfirm={async () => {
          dispatch(logout());
          localStorage.removeItem('token');
          navigate('/');
          setModalOpen(false);
        }}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      {isOpen && <Contact setContactPopup={setIsOpen} />}
    </>
  );
};

export default HeaderProfile;
