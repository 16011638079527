import React from 'react';
import { sanitize } from 'isomorphic-dompurify';
import Tag from './Tag';
import ArrowRight from './ArrowRight';

const domain = 'https://api.malwamarket.com/';

function PostCard({ post }) {
  return (
    <div className="max-w-full  !mt-0">
      <a href={`#`}>
        <img
          className="mb-5 h-[200px] object-cover w-full rounded-xl bg-no-repeat  object-center "
          src={`${domain + post?.IMAGE_URL}`}
          alt={post?.TITLE}
          style={{ placeholder: 'blur' }}
        />
      </a>
      <h2 className="pb-3 px-4 text-xl font-semibold tracking-tight text-zinc-800 ">
        <a href={`#`} className="line-clamp-1">
          {post?.TITLE}
        </a>
      </h2>
      {/* <div className="flex flex-col justify-between space-y-4 md:flex-row md:space-y-0">
        
        <div className="flex select-none justify-start space-x-2 md:hidden md:justify-end">
          {post.metadata.categories &&
            post.metadata.categories.map((category) => (
              <Tag key={category.title}>{category.title}</Tag>
            ))}
        </div>
      </div> */}
      <div
        className=" px-4  line-clamp-3 text-zinc-500 "
        dangerouslySetInnerHTML={{
          __html: sanitize(post?.DESCRIPTION) ?? '',
        }}
      />
      <div className="flex pb-4  px-4 items-center justify-between font-medium text-green-600 ">
        <a href={`#`}>
          <div className="flex items-center space-x-2">
            <span>Read more</span>
            <ArrowRight className="h-4 w-4 text-inherit" />
          </div>
        </a>
        {/* <div className="hidden select-none justify-end space-x-2 md:flex ">
          {post.metadata.categories &&
            post.metadata.categories.map((category) => (
              <Tag key={category.title}>{category.title}</Tag>
            ))}
        </div> */}
      </div>
    </div>
  );
}

export default PostCard;
