import React, { useEffect } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import { store } from '../redux/store';
import { useSelector } from 'react-redux';
import { fetchCatSubList, fetchSliderImg } from '../redux/slice/dataSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import Sliders from '../components/Sliders';

const SubCategory = () => {
  const { cat_sub_list, slider_img_list } = useSelector(
    (state) => state.alldata
  );
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state, 'abhisehk');

  const banner_top = slider_img_list?.filter(
    (v) => v.PAGE_NAME === 'Sub-Categoty Top'
  )[0]?.DATA_BASED_ON_PAGE_NAME;
  const banner_bottom = slider_img_list?.filter(
    (v) => v.PAGE_NAME === 'Sub-Categoty Bottom'
  )[0]?.DATA_BASED_ON_PAGE_NAME;

  useEffect(() => {
    store.dispatch(fetchSliderImg());
    store.dispatch(fetchCatSubList());
  }, []);

  useEffect(() => {
    if (cat_sub_list?.filter((val) => val.ID === state.ID)?.length === 0) {
      
      navigate(`/${state.C_NAME}/service-providers`, {
        state: {
          name: 'maincategory',
          id: state?.ID,
          url: [state.C_NAME],
        },
      });
    }
  }, [cat_sub_list, state, navigate]);

  // const handleClick = (id, ID) => {
  //   console.log(id, ID);
  //   navigate(`/${state.C_NAME}/${id}/sub-categories2`, {
  //     state: { ID: ID?.ID, url: [state.C_NAME], S_NAME: id },
  //   });
  // };
  const handleClick = (id, ID) => {
    navigate(`/${state.C_NAME}/${id}/service-providers`, {
      state: {
        name: 'subcategory2',
        id: ID,
        url: [state.C_NAME],
      },
    });
  };

  return (
    <div className="px-4 md:px-10">
      <Breadcrumb current_page={'Sub Category'} />
      <Sliders list={banner_top} list2={banner_bottom} />
      <div className="mb-5">
        <h1 className="text-2xl mt-4 font-sans font-semibold capitalize">
          {cat_sub_list?.filter((val) => val.ID === state.ID)[0]?.C_NAME}
        </h1>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        {cat_sub_list
          ?.filter((val) => val.ID === state.ID)[0]
          ?.SERVICE_INFO?.map((v, i) => (
            <div
              key={i}
              onClick={() =>
                handleClick(v.S_NAME?.trim().replace(/ /g, '-'), v)
              }
              className="flex w-full items-center gap-4 cursor-pointer relative group h-20 rounded-lg bg-white box_shadow px-8 transition-all duration-300 ease-in-out overflow-visible"
            >
              <img
                src={process.env.REACT_APP_IMAGE_URL + v.WEB_URL}
                className="h-[40px] w-[40px] object-cover transition-all duration-300 ease-in-out group-hover:h-[45px] group-hover:w-[45px]"
              />
              <p className="">{v.S_NAME}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SubCategory;
